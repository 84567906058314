@import url('https://fonts.googleapis.com/css2?family=Albert+Sans&family=Water+Brush&display=swap');

*{
  box-sizing: border-box;
}
html {
  height:100%;
}
#root {
  width:100%;
  height:100%;
}
.App{
  display:flex;
  flex-flow:column;
  height:100%;
}
.bs_main_tile {
  width: 400px;
  width:400px;
  height:100%;
  display: block;
  margin:auto;
}
@media only screen and (max-width: 400px) {
.bs_main_tile {
    width:100%;
  }
}
.mantine-Header-root{
  margin-bottom: 30px !important;
}
a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
.bs_gray {
  background: #888888 !important;
}
.bs_main_box {
  width: 100%;
  padding:20px;
  border-radius: 2rem;
  border-style: solid;
  border-width: 1px;
}
.bs_main_box_light {
  background: #ffffff;
  border-color: #eaeaea;
}
.bs_main_box_dark {
  background:#4c5265;
  border-color:#383c50;
}
body {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  font-family: "Albert Sans", Verdana, sans-serif;
}
.bs_button {
  font-weight: bold;
  text-align: center;
  width: 265px;
  border: none;
  border-radius: 20px;
  height: 50px;
  cursor: pointer;
  user-select: none;
  font-family: "Albert Sans", Verdana, sans-serif;
  background: #085df8;
  color: white;
}
.bs_half_button {
  width:120px !important;
  margin-left:10px;
}
.bs_landing{
  display:grid;
  align-items:center;
  grid-template-rows: repeat(3, 1fr);
  text-align:center;
}
.bs_needsauth{
  grid-template-rows: repeat(2, 1fr) !important;
}
.bs_input_section, .grid-center {
  display: grid;
  place-items: center;
}
.bs_text_input {
  padding: 15px 25px 15px 25px;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 265px;
  height: 45px;
  font-weight: 600;
  font-family: "Albert Sans", Verdana, sans-serif;
  font-size:.8em;
}
.bs_text_input_light {
  border: 2px solid #eaeaea;
}
.bs_text_input_dark {
  border: 2px solid #373A40;
  background: #25262b;
  color: #c1c2c5;
}
.curate_box{
  display:grid;
  grid-template-columns: repeat(3, auto);
  align-items:center;
  grid-template-rows: 1fr auto 1fr;
  text-align:center;
}
.book_title_head{
  grid-column: 2;
  grid-row: 1;
}
#bad{
  grid-column: 1;
  grid-row: 2;
}
#good{
  grid-column: 3;
  grid-row: 2;
}
#report{
  grid-column: 2;
  grid-row: 3;
}
.spine_container{
  grid-column: 2;
  grid-row: 2;
  text-align:center;
}
.emoji_rater{
  font-size:2em;
}
.pointer_no_select{
  cursor:pointer;
  user-select:none;
}
.curate_spine{
  max-width:50px;
}
#book_name_href{
  font-size:2.5em;
  text-decoration: none;
}
.upload_activity{
  display:grid;
  grid-template-columns: 1fr 1fr;
}
.form_elements{
  display:grid;
  grid-template-columns: 1fr;
}
.spine_preview{
  display:grid;
  place-items:center;
}
.no_img_selected{
  width:75px;
  background:grey;
  height:500px;
  display:grid;
  place-items:center;
  font-size:80px;
  color:white;
  user-select: none;
}
.uploaded_img{
  max-height:500px;
}
.bs_button {
  font-weight: bold;
  text-align: center;
  width: 265px;
  border: none;
  border-radius: 20px;
  height: 50px;
  cursor: pointer;
  user-select: none;
  background: #085df8;
  color: white;
  font-family: 'Albert Sans', Verdana, sans-serif;
  font-size:1em;
}
#upload_img_override_btn{
  display:grid;
  align-items:center;
  margin-bottom:25px;
}
.bs_loading{
  width:100px;
  height:100px;
  margin:50px auto;
  display:block;
}
.bs_loading_square_container{
  width:100%;
  height:100%;
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.bs_loading_square{
  width:50px;
  height:50px;
  opacity:75%;
}
#sq1{
  background:#D3D3D3;
  grid-row:1;
  grid-column:1;
}
#sq2{
  background:#BDBDBD;
  grid-row:1;
  grid-column:2;
}
#sq4{
  background:#9E9E9E;
  grid-row:2;
  grid-column:2;
}
#sq3{background:#7D7D7D;
  grid-row:2;
  grid-column:1;
}
#moving_square{
  width:50px;
  height:50px;
  background:#696969;
  grid-row:1;
  grid-column:1;
  animation-name: move_box;
  animation-iteration-count: infinite;
  animation-duration: .8s;
}

@keyframes move_box{
  25%  {
    grid-row:1;
    grid-column:2;
  }
  50%  {
    grid-row:2;
    grid-column:2;
  }
  75% {
    grid-row:2;
    grid-column:1;
  }
}
.bs_adaptive_button {
  text-align: center;
  display:grid;
  align-items:center;
  border: none;
  border-radius: 20px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  user-select: none;
  font-family: "Albert Sans", Verdana, sans-serif;
  background: #085df8;
  color: white;
  margin: auto;
}
.found_spine_box{
text-align:center;
}
.bs_head{
  margin-bottom:10px;
  font-weight:500;
  font-size:2em;
  width:100%;
  text-align:center;
}
.found_spine_head{
  margin-bottom:10px;
  display:flex;
  justify-content: space-between;
  font-weight:500;
  font-size:2em;
  width:100%;
  text-align:center;
}
.bs_edit_pencil_yourshelf {
  text-align: right;
  cursor: pointer;
}
.bs_button_wrapper{
  width:100%;
  margin-top:10px;
}
.found_spine_box{
text-align:center;
}
.bs_button_wrapper{
  width:100%;
  margin-top:10px;
}
.display_shelf {
  max-width: 350px;
  margin-bottom: 15px;
}
.bs_shelf_buttons {
  text-align: center;
  display:grid;
  align-items:center;
  border: none;
  border-radius: 20px;
  padding: 10px 15px 10px 15px;
  width:150px;
  cursor: pointer;
  user-select: none;
  font-family: "Albert Sans", Verdana, sans-serif;
  background: #085df8;
  color: white;
  margin: auto;
}
.multiple_button_wrapper
{
  display:grid;
  grid-template-columns: 1fr 1fr;
}
.found_spine_box{
  text-align:center;
}
.bs_button_wrapper{
  width:100%;
  margin-top:10px;
}
.leaderboard_row{
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 10px;
}
.leaderboard_name{
  text-align:center;
}
.leaderboard_score{
  text-align:right;
}
.bs_box_line {
  background: #eaeaea;
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
}
.title_row{
  font-weight:700;
}

.unfound_box{
  width:350px;
  max-height:70%;
  overflow-y:auto;
}
.unfound_row{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom:10px;
}
.bs_upload_unfound {
  background: #085df8;
  color: white;
  width:90px;
  margin-right:0;
  margin-left:auto;
  display:block;
}
.bs_unfound_book_title {
  margin-left:0;
  margin-right:auto;
  display:block;
  font-style: italic;
}

.bs_unfound_title{
  font-weight: 600;
  font-size:20px;
  text-align:center;
}
.bs_title_arrow{
  width:100%;
  display:grid;
  grid-template-columns: .5fr 2fr .5fr;
  margin-bottom:10px;
}
.arrow {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  user-select: none;
}
.arrow_light {
  border-top: 2px solid #000;
  border-right: 2px solid #000;
}
.arrow_dark {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.arrow-left {
  transform: rotate(-135deg);
  margin-left:5px;
  margin-top:5px;
}
.arrow-down {
  transform: rotate(135deg);
}
.arrow-right {
  transform: rotate(45deg);
}
.unfound_spine_subhead{
  margin-bottom: 5px;
}
.bs_registerlogin_flip{
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: -5px;
  color: #085df8;
  cursor:pointer;
}
/* .bs_registerlogin_flip:hover{
  color: #053ea8;
} */
.bs_failed_input{
  border-color:red;
}
.bs_profile_title{
  width:100%;
  margin-bottom:20px;
  text-align: center;
}
.bs_gr_id_row{
  display:grid;
  grid-template-columns: 1fr 1fr;
  place-items:center;
  margin-bottom:20px;
}
.bs_logout_button{
  width:80%;
  background:#999999;
  color:white;
}
.bs_submissions_row{
  width:100%;
  display:grid;
  grid-template-columns: 2.5fr .5fr;
  margin-bottom:20px;
  user-select: none;
  cursor:pointer;
}
.bs_gr_id_input{
  width:80%;
  margin-bottom:0;
}
.bs_enter_button {
  background: #085df8;
  color: white;
}
.bs_gr_id_button{
  width:80%;
}
.bs_center_grid{
  display:grid;
  place-items:center;
}
.custom-select{
  display:block;
  margin:auto;
}
.bs_disabled{
  cursor:not-allowed;
  filter:grayscale(1);
}
.shelf_image_container_container{
  width:120px;
  height:225px;
  display: grid;
  place-items: center;
}
.shelf_image_element_container{
  width: 80%;
  height: 90%;
  padding: 5px;
  border-radius:10px;
  box-shadow: #696969;
  cursor: pointer;
}
.shelf_image_element_container_light{
  background:#eaeaea;
}
.shelf_image_element_container_dark{
  background:#222222;
}
.shelf_image_element_img{
  width:75px;
}
.hide{
  display: none;
}
.shelves_section_grid{
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 240px);
}
.shelf_image_date{
  font-weight:600;
  margin-top: 5px;
  user-select: none;
}
.profile_err_text_container{
  margin-bottom:15px;
}
.profile_err_text{
  font-style: italic;
}
.bs_title_container{
  display:grid;
  grid-template-columns: 1fr 1fr;
  place-items:center;
}
.mantine-Switch-track {
  cursor:pointer;
}
a {
  text-decoration: none;
}
.a_ {
  cursor: pointer;
}
.a_dark {
  color:skyblue;
}
.a_light {
  color:blue;
}
.bs_filler_div {
  flex: 1 1;
}
.bs_faq_img {
  max-width: 100%;
}
.bs_delete_bg_color {
  background: crimson;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.text-left {
  text-align: left;
}